import styles from './styles.module.scss';
import { useReducer, useEffect } from 'react';
import { notificationService } from '../../services';
import { capitalizeFirstLetter } from '../../helpers';
import {
  MultipleCheckboxItems,
  LenderItem,
  Accordion,
  InputFile,
  Checkbox,
  Button,
  Input,
} from '../../components';
import {
  GROUP_MULTIPLE_VALUE_ITEMS,
  TREATMENT_INTEREST_ITEMS,
  COVERED_LOCATION_ITEMS,
  EXPERIENCE_TYPE_ITEMS,
  VALUATION_TYPE_ITEMS,
  BOOLEAN_DATA_ITEMS,
  ROLE_NAME_USER,
} from '../../common/constants';

const selectDefaultValue = 0;

const combinedInputItems = [
  {
    title: 'Rate (%)',
    components: [{ key: 'min_rate' }, { key: 'max_rate' }],
  },
  {
    title: 'Loan ($)',
    components: [{ key: 'min_loan' }, { key: 'max_loan' }],
  },
  {
    title: 'Fixed rate term (years)',
    components: [{ key: 'min_fixed_rate_term' }, { key: 'max_fixed_rate_term' }],
  },
  {
    title: 'Term (years)',
    components: [{ key: 'min_term' }, { key: 'max_term' }],
  },
  {
    title: 'Age (years)',
    components: [{ key: 'min_age' }, { key: 'max_age' }],
  },
  {
    title: 'Loan processing time (days)',
    components: [{ key: 'min_loan_processing_time' }, { key: 'max_loan_processing_time' }],
  },
  {
    title: 'Decision time (hours)',
    components: [{ key: 'min_decision_time' }, { key: 'max_decision_time' }],
  },
  {
    title: 'Trading period (months)',
    components: [{ key: 'min_trading_period' }],
  },
  {
    title: 'Loan to value (%)',
    components: [{ key: 'max_loan_to_value' }],
  },
];

const selectVariantItems = [
  {
    value: selectDefaultValue,
    label: 'Not selected',
  },
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const stateValidation = (state) => {
  const values = Object.values(state);
  const keys = Object.keys(state);
  const data = {};

  for (const i in values) {
    const value = values[i];
    /* if value is empty || value !== false(default checkboxes) -> continue */
    if (value !== false && !value) continue;

    /* if array is empty -> continue */
    if (Array.isArray(value) && !value.length) continue;

    data[keys[i]] = value;
  }

  return data;
};

const initialState = {
  financeTypeId: selectDefaultValue,

  image: '',
  title: '',
  sectors: '',

  additional_info: '',
  web_link: '',
  phone: '',
  email: '',

  /* % */
  min_rate: '',
  max_rate: '',

  /* $ */
  min_loan: '',
  max_loan: '',

  /* years */
  min_fixed_rate_term: '',
  max_fixed_rate_term: '',

  /* years */
  min_term: '',
  max_term: '',

  /* years */
  min_age: '',
  max_age: '',

  /* days */
  min_loan_processing_time: '',
  max_loan_processing_time: '',

  /* hours */
  min_decision_time: '',
  max_decision_time: '',

  /* months */
  min_trading_period: '',

  /* % */
  max_loan_to_value: '',

  is_early_repayment_charges: selectDefaultValue,
  is_personal_guarantee: selectDefaultValue,
  is_owner_occupant: selectDefaultValue,
  is_interest_only: selectDefaultValue,

  treatment_interest: [],
  covered_location: [],
  experience_type: [],
  valuation_type: [],
  // financeTypeIds: [],
};

function reducer(state, action) {
  switch (action.type) {
    /* for inputs */
    case 'SAVE_INPUT_VALUE': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }
    /* for inputs */

    /* for checkboxes group */
    case 'SAVE_CHECKBOX_ITEM': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: [...state[key], value],
      };
    }
    case 'REMOVE_CHECKBOX_ITEM': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: [...state[key].filter((stateValue) => stateValue !== value)],
      };
    }
    /* for checkboxes group */

    /* for checkboxes */
    case 'SAVE_CHECKBOX_VALUE': {
      const { value, key } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }
    /* for checkboxes */
    default: {
      return { ...state };
    }
  }
}

const AddEditLenderModalContent = ({
  financeTypeId,
  financeTypes,
  isDarkMode,
  dataContent = {},
  getAllFinanceTypesAction,
  updateLenderAction,
  deleteLenderAction,
  addLenderAction,
}) => {
  useEffect(() => {
    financeTypes && !financeTypes.length && getAllFinanceTypesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    financeTypeId,
    ...dataContent,
  });
  const isEdit = Boolean(dataContent.id); // if dataContent has id -> is edit mode

  const lenderHandler = () => {
    const { financeTypeId, image, title } = state;
    if (!financeTypeId || !title || !image) {
      return notificationService.error('Image, title and finance type fields are required');
    }
    const validatedData = stateValidation(state);
    isEdit ? updateLenderAction({ ...validatedData }) : addLenderAction({ ...validatedData });
  };

  const inputHandler = ({ target: { value, name } }) => {
    const correctValue = typeof value === 'string' ? value.replace(/,/g, '.') : value;
    dispatch({ type: 'SAVE_INPUT_VALUE', payload: { value: correctValue, key: name } });
  };

  const checkboxHandler = ({ target, target: { checked, name } }) => {
    const key = target.getAttribute('data-key');
    if (
      key === 'treatment_interest' ||
      key === 'covered_location' ||
      key === 'experience_type' ||
      key === 'valuation_type'
      // key === 'financeTypeIds'
    ) {
      const type = checked ? 'SAVE_CHECKBOX_ITEM' : 'REMOVE_CHECKBOX_ITEM';
      dispatch({ type, payload: { value: name, key } });
      return;
    }

    dispatch({ type: 'SAVE_CHECKBOX_VALUE', payload: { value: checked, key: name } });
  };

  const setImage = (value) => {
    const key = 'image';
    dispatch({ type: 'SAVE_INPUT_VALUE', payload: { value, key } });
  };

  const deleteLender = () => {
    const id = dataContent.id;
    deleteLenderAction(id);
  };

  const selectFinanceTypeItems = [
    {
      value: selectDefaultValue,
      label: 'Not selected',
    },
    ...(financeTypes || []).map(({ title, id }) => ({
      value: id,
      label: title,
    })),
  ];

  const setSelectDefaultValue = (key) => (isEdit ? dataContent[key] : selectDefaultValue);

  return (
    <div className={styles['AddEditLenderModalContent']}>
      <div className={styles['content']}>
        {/* {!isEdit && (
          <div className={styles['content-item']}>
            <Accordion title="Finance type">
              {financeTypes.map(({ title, id }) => {
                const equalWithCurrentFinanceTypeId = Number(financeTypeId) === id;
                const matchInStateItems = state.financeTypeIds.find(
                  (stateId) => Number(stateId) === id
                );
                const isChecked = Boolean(matchInStateItems);
                return (
                  <div key={id} className={styles['checkbox-wrapper']}>
                    <Checkbox
                      dataKey="financeTypeIds"
                      isDisabled={equalWithCurrentFinanceTypeId}
                      isChecked={equalWithCurrentFinanceTypeId || isChecked}
                      label={title}
                      name={String(id)}
                      onChange={checkboxHandler}
                    />
                  </div>
                );
              })}
            </Accordion>
          </div>
        )} */}

        {/* {!isEdit && (
          <div className={styles['content-item']}>
            <Input
              label="Finance type"
              name="financeTypeId"
              isDarkMode={isDarkMode}
              value={state.financeTypeId}
              onChange={inputHandler}
              defaultValue={setSelectDefaultValue('financeTypeId')}
              selectItems={selectFinanceTypeItems}
            />
          </div>
        )} */}
        <div className={styles['content-item']}>
          <InputFile setImage={setImage} />
        </div>
        <div className={styles['content-item']}>
          <Input
            label="Title"
            name="title"
            isDarkMode={isDarkMode}
            value={state.title}
            onChange={inputHandler}
          />
        </div>
        <div className={styles['content-item']}>
          <Input
            label="Additional information"
            name="additional_info"
            rows={4}
            value={state.additional_info}
            isDarkMode={isDarkMode}
            onChange={inputHandler}
          />
        </div>
        <div className={styles['content-item']}>
          <Input
            label="Web link"
            name="web_link"
            isDarkMode={isDarkMode}
            value={state.web_link}
            onChange={inputHandler}
          />
        </div>
        <div className={styles['content-item']}>
          <Input
            label="Phone number"
            name="phone"
            isDarkMode={isDarkMode}
            value={state.phone}
            onChange={inputHandler}
          />
        </div>
        <div className={styles['content-item']}>
          <Input
            label="Email"
            name="email"
            isDarkMode={isDarkMode}
            value={state.email}
            onChange={inputHandler}
          />
        </div>
        {BOOLEAN_DATA_ITEMS.map(({ title, key }) => (
          <div key={key} className={styles['content-item']}>
            <Input
              label={title}
              name={key}
              isDarkMode={isDarkMode}
              value={state[key]}
              onChange={inputHandler}
              defaultValue={setSelectDefaultValue(key)}
              selectItems={selectVariantItems}
            />
          </div>
        ))}
        <MultipleCheckboxItems
          dataContent={state}
          groupItems={GROUP_MULTIPLE_VALUE_ITEMS}
          isEdit={isEdit}
          checkboxHandler={checkboxHandler}
        />
        {combinedInputItems.map(({ components, title }) => {
          /* { components: [{ key }], title?: string } */
          return (
            <div key={title} className={styles['content-item']}>
              <Accordion title={title} isExpanded={isEdit}>
                {components.map(({ key }) => {
                  const label = capitalizeFirstLetter(key.replace(/_/g, ' '));
                  return (
                    <div key={key} className={styles['input-wrapper']}>
                      <Input
                        label={label}
                        name={key}
                        isDarkMode={isDarkMode}
                        value={state[key]}
                        onChange={inputHandler}
                      />
                    </div>
                  );
                })}
              </Accordion>
            </div>
          );
        })}
        <div className={styles['content-item']}>
          <Input
            label="Sectors"
            name="sectors"
            rows={4}
            value={state.sectors}
            isDarkMode={isDarkMode}
            onChange={inputHandler}
          />
        </div>
        <div className={styles['btn-wrapper']}>
          <Button onClick={lenderHandler}>{isEdit ? 'Edit lender' : 'Add lender'}</Button>
        </div>
        {isEdit && (
          <div className={styles['btn-wrapper']}>
            <Button isError onClick={deleteLender}>
              Delete lender
            </Button>
          </div>
        )}
      </div>

      <div className={styles['preview']}>
        <LenderItem
          dataContent={{
            image: state.image,
            title: state.title,
          }}
          userRole={ROLE_NAME_USER} // without edit tools
          handler={() => {
            /* without handler on MORE button */
          }}
        />
      </div>
    </div>
  );
};

export { AddEditLenderModalContent };
