const ROLE_NAME_ADMIN = 'admin';
const ROLE_NAME_USER = 'user';

/* lender location covered */
const COVERED_LOCATION_Nationwide = 'Nationwide';
const COVERED_LOCATION_Alabama = 'Alabama';
const COVERED_LOCATION_Alaska = 'Alaska';
const COVERED_LOCATION_Arizona = 'Arizona';
const COVERED_LOCATION_Arkansas = 'Arkansas';
const COVERED_LOCATION_California = 'California';
const COVERED_LOCATION_Colorado = 'Colorado';
const COVERED_LOCATION_Connecticut = 'Connecticut';
const COVERED_LOCATION_Delaware = 'Delaware';
const COVERED_LOCATION_DC = 'District of Columbia';
const COVERED_LOCATION_Florida = 'Florida';
const COVERED_LOCATION_Georgia = 'Georgia';
const COVERED_LOCATION_Hawaii = 'Hawaii';
const COVERED_LOCATION_Idaho = 'Idaho';
const COVERED_LOCATION_Illinois = 'Illinois';
const COVERED_LOCATION_Indiana = 'Indiana';
const COVERED_LOCATION_Iowa = 'Iowa';
const COVERED_LOCATION_Kansas = 'Kansas';
const COVERED_LOCATION_Kentucky = 'Kentucky';
const COVERED_LOCATION_Louisiana = 'Louisiana';
const COVERED_LOCATION_Maine = 'Maine';
const COVERED_LOCATION_Maryland = 'Maryland';
const COVERED_LOCATION_Massachusetts = 'Massachusetts';
const COVERED_LOCATION_Michigan = 'Michigan';
const COVERED_LOCATION_Minnesota = 'Minnesota';
const COVERED_LOCATION_Mississippi = 'Mississippi';
const COVERED_LOCATION_Missouri = 'Missouri';
const COVERED_LOCATION_Montana = 'Montana';
const COVERED_LOCATION_Nebraska = 'Nebraska';
const COVERED_LOCATION_Nevada = 'Nevada';
const COVERED_LOCATION_New_Hampshire = 'New Hampshire';
const COVERED_LOCATION_New_Jersey = 'New Jersey';
const COVERED_LOCATION_New_Mexico = 'New Mexico';
const COVERED_LOCATION_New_York = 'New York';
const COVERED_LOCATION_North_Carolina = 'North Carolina';
const COVERED_LOCATION_North_Dakota = 'North Dakota';
const COVERED_LOCATION_Ohio = 'Ohio';
const COVERED_LOCATION_Oklahoma = 'Oklahoma';
const COVERED_LOCATION_Oregon = 'Oregon';
const COVERED_LOCATION_Pennsylvania = 'Pennsylvania';
const COVERED_LOCATION_Rhode_Island = 'Rhode Island';
const COVERED_LOCATION_South_Carolina = 'South Carolina';
const COVERED_LOCATION_South_Dakota = 'South Dakota';
const COVERED_LOCATION_Tennessee = 'Tennessee';
const COVERED_LOCATION_Texas = 'Texas';
const COVERED_LOCATION_Utah = 'Utah';
const COVERED_LOCATION_Vermont = 'Vermont';
const COVERED_LOCATION_Virginia = 'Virginia';
const COVERED_LOCATION_Washington = 'Washington';
const COVERED_LOCATION_West_Virginia = 'West Virginia';
const COVERED_LOCATION_Wisconsin = 'Wisconsin';
const COVERED_LOCATION_Wyoming = 'Wyoming';

const COVERED_LOCATION_ITEMS = [
  COVERED_LOCATION_Nationwide,
  COVERED_LOCATION_Alabama,
  COVERED_LOCATION_Alaska,
  COVERED_LOCATION_Arizona,
  COVERED_LOCATION_Arkansas,
  COVERED_LOCATION_California,
  COVERED_LOCATION_Colorado,
  COVERED_LOCATION_Connecticut,
  COVERED_LOCATION_Delaware,
  COVERED_LOCATION_DC,
  COVERED_LOCATION_Florida,
  COVERED_LOCATION_Georgia,
  COVERED_LOCATION_Hawaii,
  COVERED_LOCATION_Idaho,
  COVERED_LOCATION_Illinois,
  COVERED_LOCATION_Indiana,
  COVERED_LOCATION_Iowa,
  COVERED_LOCATION_Kansas,
  COVERED_LOCATION_Kentucky,
  COVERED_LOCATION_Louisiana,
  COVERED_LOCATION_Maine,
  COVERED_LOCATION_Maryland,
  COVERED_LOCATION_Massachusetts,
  COVERED_LOCATION_Michigan,
  COVERED_LOCATION_Minnesota,
  COVERED_LOCATION_Mississippi,
  COVERED_LOCATION_Missouri,
  COVERED_LOCATION_Montana,
  COVERED_LOCATION_Nebraska,
  COVERED_LOCATION_Nevada,
  COVERED_LOCATION_New_Hampshire,
  COVERED_LOCATION_New_Jersey,
  COVERED_LOCATION_New_Mexico,
  COVERED_LOCATION_New_York,
  COVERED_LOCATION_North_Carolina,
  COVERED_LOCATION_North_Dakota,
  COVERED_LOCATION_Ohio,
  COVERED_LOCATION_Oklahoma,
  COVERED_LOCATION_Oregon,
  COVERED_LOCATION_Pennsylvania,
  COVERED_LOCATION_Rhode_Island,
  COVERED_LOCATION_South_Carolina,
  COVERED_LOCATION_South_Dakota,
  COVERED_LOCATION_Tennessee,
  COVERED_LOCATION_Texas,
  COVERED_LOCATION_Utah,
  COVERED_LOCATION_Vermont,
  COVERED_LOCATION_Virginia,
  COVERED_LOCATION_Washington,
  COVERED_LOCATION_West_Virginia,
  COVERED_LOCATION_Wisconsin,
  COVERED_LOCATION_Wyoming,
];

/* lender interest treatment */
const TREATMENT_INTEREST_INTEREST_ONLY = 'Interest only';
const TREATMENT_INTEREST_ROLLED_UP = 'Rolled up';
const TREATMENT_INTEREST_REPAYMENT = 'Repayment';
const TREATMENT_INTEREST_RETAINED = 'Retained';
const TREATMENT_INTEREST_SERVICED = 'Serviced';

const TREATMENT_INTEREST_ITEMS = [
  TREATMENT_INTEREST_INTEREST_ONLY,
  TREATMENT_INTEREST_ROLLED_UP,
  TREATMENT_INTEREST_REPAYMENT,
  TREATMENT_INTEREST_RETAINED,
  TREATMENT_INTEREST_SERVICED,
];

/* lender valuation type */
const VALUATION_TYPE_MVA = 'MVA';
const VALUATION_TYPE_AVM = 'AVM';

const VALUATION_TYPE_ITEMS = [VALUATION_TYPE_MVA, VALUATION_TYPE_AVM];

/* lender applicant type */
const APPLICANT_TYPE_SHARIA_COMPLIANT = 'SHARIA COMPLIANT';
const APPLICANT_TYPE_PARTNERSHIP = 'PARTNERSHIP';
const APPLICANT_TYPE_INDIVIDUAL = 'INDIVIDUAL';
const APPLICANT_TYPE_COMPLEX = 'COMPLEX';
const APPLICANT_TYPE_EX_PATS = 'EX-PATS';
const APPLICANT_TYPE_SIPPS = 'SIPPS';
const APPLICANT_TYPE_TRUST = 'TRUST';
const APPLICANT_TYPE_LLP = 'LLP';
const APPLICANT_TYPE_PLC = 'PLC';
const APPLICANT_TYPE_LTD = 'LTD';

const APPLICANT_TYPE_ITEMS = [
  APPLICANT_TYPE_SHARIA_COMPLIANT,
  APPLICANT_TYPE_PARTNERSHIP,
  APPLICANT_TYPE_INDIVIDUAL,
  APPLICANT_TYPE_COMPLEX,
  APPLICANT_TYPE_EX_PATS,
  APPLICANT_TYPE_SIPPS,
  APPLICANT_TYPE_TRUST,
  APPLICANT_TYPE_LLP,
  APPLICANT_TYPE_PLC,
  APPLICANT_TYPE_LTD,
];

/* lender experience type */
const EXPERIENCE_TYPE_EXPERIENCED = 'Experienced';
const EXPERIENCE_TYPE_FIRST_TIME = 'First time';
const EXPERIENCE_TYPE_FLEXIBLE = 'Flexible';

const EXPERIENCE_TYPE_ITEMS = [
  EXPERIENCE_TYPE_EXPERIENCED,
  EXPERIENCE_TYPE_FIRST_TIME,
  EXPERIENCE_TYPE_FLEXIBLE,
];

const GROUP_MULTIPLE_VALUE_ITEMS = [
  { items: TREATMENT_INTEREST_ITEMS, dataKey: 'treatment_interest', title: 'Interest Treatment' },
  { items: COVERED_LOCATION_ITEMS, dataKey: 'covered_location', title: 'Location Covered' },
  { items: EXPERIENCE_TYPE_ITEMS, dataKey: 'experience_type', title: 'Experience Type' },
  { items: VALUATION_TYPE_ITEMS, dataKey: 'valuation_type', title: 'Valuation Type' },
];
const BOOLEAN_DATA_ITEMS = [
  { key: 'is_early_repayment_charges', title: 'Early repayment charges' },
  { key: 'is_personal_guarantee', title: 'Personal Guarantee' },
  { key: 'is_owner_occupant', title: 'Owner occupant' },
  { key: 'is_interest_only', title: 'Interest Only' },
];

export {
  GROUP_MULTIPLE_VALUE_ITEMS,
  TREATMENT_INTEREST_ITEMS,
  COVERED_LOCATION_ITEMS,
  EXPERIENCE_TYPE_ITEMS,
  VALUATION_TYPE_ITEMS,
  BOOLEAN_DATA_ITEMS,
  ROLE_NAME_ADMIN,
  ROLE_NAME_USER,
};
